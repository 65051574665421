import React from "react"
import { Link } from "gatsby"


const OtherCaseStudy = () => (

    <div className="other-case-study py60 container">
        <h2>Our other success stories.</h2>
        <div className="studies">
            <div className="study-box">
                <h3>Liva Pastacılık</h3>
                <p>Liva Pastacılık: Our e-commerce brand for which we provide digital marketing consultancy, search engine optimization and digital ads services. </p>
                <div className="boxs">
                    <div className="box">
                        <span>%5.79</span>
                        <p>E-Commerce turnover conversion rate</p>
                    </div>
                    <div className="box">
                        <span>+4.500</span>
                        <p>monthly orders</p>
                    </div>
                </div>

                <Link to="/success-stories/liva-pastacilik/">Check out the success story</Link>
            </div>
            <div className="study-box">
                <h3>TCDD Taşımacılık</h3>
                <p>Website UI/UX design, software development and search engine optimization (SEO) services.</p>
                <br/>
                
                <div className="boxs">
                    <div className="box">
                        <span>+%24</span>
                        <p>Increase in conversion rate</p>
                    </div>
                    <div className="box">
                        <span>+%125</span>
                        <p>Organic traffic growth</p>
                    </div>
                </div>

                <Link to="/success-stories/tcdd-tasimacilik/">Check out the success story</Link>
            </div>
        </div>
    </div>
)

export default OtherCaseStudy
